<template>
    <header class="site-header sticky-top py-1">
        <nav class="container d-flex flex-column flex-md-row justify-content-between">
            <a class="py-2" href="https://immosystem.com.mx/" aria-label="Product">
                <img class="logo" src="images/Horizontal_Negro.png" alt="IMMO CRM 360">
            </a>
            
            <a class="py-2 d-none d-md-inline-block" :href="'mailto:' + info.reserveemail"> <i class="far fa-envelope"></i> {{ info.reserveemail }}</a>
            <a class="py-2 d-none d-md-inline-block" :href="'tel:' + info.reservephone"> <i class="fas fa-phone-alt"></i>  {{ info.reservephone }}</a>
            <a class="py-2 " data-toggle="tooltip" data-placement="top" title="Contactar por WhatsApp" :href="'https://api.whatsapp.com/send?text=Buen día, estoy interesado(a) en recibir información sobre una de las plantillas disponibles para mi sitio web. (https://templates.immocrm360.com.mx/)&amp;phone='+ info.phone" target="_blank" ><i class="fab fa-whatsapp"></i>  {{ info.phone }}</a>
        </nav>
    </header>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>
<style scoped>
.logo{
    width: 52vw;
}
@media(min-width:992px){
  .logo{
    width: 11vw;
}

}
</style>