import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import NotFound from '@/views/Error.vue';

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
        name: 'Error',
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router