import { createStore } from 'vuex'

export default createStore({
    state: {
        sites: [{
                "key": 0,
                "image": 'homeland-immotemp.png',
                "name": 'Homeland',
                "look": 'dark',
                "url": 'https://homeland.immocrm360.com.mx',
                "imgBackground": "light"
            },
            {
                "key": 1,
                "image": 'royalstate-immotemp.png',
                "name": 'Royal Estate',
                "look": 'light',
                "url": 'https://royalestate.immocrm360.com.mx',
                "imgBackground": "dark"
            },
            {
                "key": 2,
                "image": 'loreen-immotemp.png',
                "name": 'Loreen',
                "look": 'light',
                "url": 'https://loreen.immocrm360.com.mx',
                "imgBackground": "body"
            },
            {
                "key": 3,
                "image": 'cozy-immotemp.png',
                "name": 'Cozy',
                "look": 'dark',
                "url": 'https://cozy.immocrm360.com.mx',
                "imgBackground": "light"
            },

        ],
        ID_COMPANY: "4",
        INFO_COMPANY: {},
        API_URL: "https://api.immocrm360.com.mx",
        API_PARAMS: {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: {},
        },
        ERROR_CODE: 0,
    },

    mutations: {
        setInfoCompany(state, info) {
            state.INFO_COMPANY = info;
        },
        setError(state, code) {
            state.ERROR_CODE = code;
        },
    },
    actions: {
        getInfoCompany({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=footer&folio=${state.ID_COMPANY}`;
            config.body = data;
            fetch(state.API_URL, config)
                .then((res) => res.json())
                .then((res) => {
                    if (res.status == 200) {
                        commit("setInfoCompany", res.data[0]);
                        commit("setError", res.status);
                    } else {
                        commit("setError", res.status);
                    }
                });
            // fetch("https://api.ipify.org?format=json")
            //     .then((res) => res.json())
            //     .then((res) => {
            //         commit("setUserIp", res);
            //     });
        },
    },
    getters: {
        getSites: (state) => (id) => {
            return state.sites.find(site => site.key == id)
        },

        _getInfoCompany(state) {
            return state.INFO_COMPANY;
        },
        _getError(state) {
            return state.ERROR_CODE;
        },
    }
})