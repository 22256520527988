<template>
    <footer class="container py-5">
        <div class="row">
            <div class="col-12 col-md-4 text-center">
                <a href="https://immosystem.com.mx/">
                    <img class="f-logo" src="images/immocrm360_vertiorig.png" alt="" srcset="">
                </a>
                <div class="footer-social-icons">
                    <ul class="social-icons">
                        <li><a href="https://www.facebook.com/IMMOCRM360/" target="_blank"  class="social-icon"> <i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/immo_system" target="_blank"  class="social-icon"> <i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/immocrm360/" target="_blank"  class="social-icon"> <i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCdgdmH5L8SquCQo-2RE-AeA" target="_blank"  class="social-icon"> <i class="fab fa-youtube"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/immo-system" target="_blank"  class="social-icon"> <i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.pinterest.com.mx/immosystem/" target="_blank"  class="social-icon"> <i class="fab fa-pinterest-p"></i></a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-12 col-md-8 text-end d-none d-md-block">
                <ul class="list-unstyled ">
                    <li><a class="link-secondary" href="https://immomarket.com.mx/" target="_blank" >immomarket.com.mx</a> <i class="fas fa-globe"></i></li>
                    <li><a class="link-secondary" href="https://immocrm360.com.mx/" target="_blank" >immocrm360.com.mx</a> <i class="fas fa-globe"></i></li>
                    <!-- <li><a class="link-secondary" href="https://agent.immosystem.com.mx/" target="_blank" >agent.immosystem.com.mx</a> <i class="fas fa-globe"></i></li> -->
                </ul>
            </div>
            <div class="col-12 col-md-8 text-center d-block d-md-none">
                <ul class="list-unstyled ">
                    <li><a class="link-secondary" href="https://immomarket.com.mx/" target="_blank" >immomarket.com.mx</a> <i class="fas fa-globe"></i></li>
                    <li><a class="link-secondary" href="https://immocrm360.com.mx/" target="_blank" >immocrm360.com.mx</a> <i class="fas fa-globe"></i></li>
                    <!-- <li><a class="link-secondary" href="https://agent.immosystem.com.mx/" target="_blank" >agent.immosystem.com.mx</a> <i class="fas fa-globe"></i></li> -->
                </ul>
            </div>
        </div>
    </footer>
    <div class="container copyright">
        <div class="row">
            <div class="col-12 text-center">
                <p> <a href="https://immocrm360.com.mx/">IMMO CRM 360<sup>©</sup></a> | Todos los derechos reservados. {{year}} | Developed with <i class="far fa-heart" aria-hidden="true"></i> & <i class="fas fa-pizza-slice" aria-hidden="true"></i>  </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            year:""
        }
    },
    created(){
        let d= new Date();
        this.year=d.getFullYear();
    }
}
</script>
<style scoped>
.items-list{
    display: flex;
   align-items: center;
}
.list-unstyled li{
    font-size: 17px;
    line-height: 35px;
}
.f-logo{
    width: 100px;
}
.copyright p{
    color: #555555;
}
 a{
    color: #212529;
}
 a:hover{
    color: #fff;
}


ul {
    padding:0;
    list-style: none;
}
.footer-social-icons {
    display:block;
    margin: 0 auto;
}
.social-icon {
    color: #fff;
}
ul.social-icons {
    margin-top: 10px;
}
.social-icons li {
    vertical-align: top;
    display: inline;
    height: 100px;
}
.social-icons a {
    color: #000;
}
.fa-facebook-f {
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: transparent;
}
.fa-facebook-f:hover {
    background-color: #3d5b99;
    color: #fff;
}
.fa-twitter {
    padding:10px 12px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: transparent;
}
.fa-twitter:hover {
    background-color: #55ACEE;
    color: #fff;
}
.fa-instagram{
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: transparent;
}
.fa-instagram:hover {
    background-color: #125688;
    color: #fff;
}
.fa-youtube {
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: transparent;
}
.fa-youtube:hover {
    background-color: #bb0000;
    color: #fff;
}
.fa-linkedin-in {
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: transparent;
}
.fa-linkedin-in:hover {
    background-color: #007bb5;
    color: #fff;
}
.fa-pinterest-p {
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: transparent;
}
.fa-pinterest-p:hover {
    background-color: #cb2027;
    color: #fff;
}
</style>