<template>
  <!-- <div :class="`bg-${currentSite.look} me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center ${(currentSite.look=='dark')?'text-white':''} overflow-hidden bg-glass`"> -->
  <div :class="`bg-${currentSite.look} mb-3 me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-immo overflow-hidden bg-glass`">
    <div class="my-3 py-3">
      <h2 class="display-5 fw-bold">{{currentSite.name}} </h2>
      <a :href="currentSite.url" target="_blank" class="lead text-immo">{{currentSite.url}}</a>
    </div>
    <div @click="goToPage(currentSite.url)" :class="`bg-${currentSite.imgBackground} shadow-sm mx-auto siteImage`" :style="` background-image:url(images/${currentSite.image})`">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteCard',
  props: {
    site: String
  },
  computed:{
    currentSite(){
      return this.$store.getters.getSites(this.site)
    }
  },
  methods:{
    goToPage(url){
      window.open(url);
    }
  }
}
</script>
<style scoped>
  a {
    color: rgb(44, 47, 67);;
  }
  a:hover{
    color: rgb(0, 3, 21);;
  }
  .siteImage{
    cursor: pointer;
    width: 80%;
    height: 300px; 
    border-radius: 21px 21px 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    transition: .3s all ease;
  }
   .siteImage:hover{
   filter:opacity(.8);
    transition: .3s all ease;
   }
</style>