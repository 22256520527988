<template>
  <Header/>
  <router-view/>
  <Footer/>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import { mapGetters } from "vuex";
export default {
  components:{
    Header,
    Footer
  },
  created() {
    this.$store.dispatch('getInfoCompany').then(() => {
      // Los datos se han obtenido, puedes acceder a ellos aquí.
    });
  }
}
</script>
